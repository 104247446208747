input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
   box-shadow: 0 0 0 60px white inset;
   background-color: #fff;
   background-clip: content-box;
   padding: 0;
}

.text-default {
   font-size: 15px;
   font-weight: 300;
}
.fs-15 {
   font-size: 15px !important;
}
.font-weight-medium {
   font-weight: 500 !important;
}
.pl-radio {
   padding-left: 1.5625rem !important;
}

#demande_cause {
   .custom-checkbox{
      b {
         font-size: 15px;
         font-weight: 300;
      }
      a {
         font-size: 15px;
      }
      .cause_upload, .promiscuity-input {
         display: none;
      }
      &.open {
         .cause_upload, .promiscuity-input {
            display: block;

            .form-upload-single-link {
               &__wrapper {
                  @media (max-width: 414px) {
                     width: 170px;
                  }
                  @media (min-width: 415px) and (max-width: 1200px) {
                     width: 270px;
                  }
               }
            }
         }
      }
   }
}

.demandeur {
   background-color: #fff;
   .select2-container--default .select2-results__group {
      display: none;
   }
   .container-spacing {
      @media (min-width: 1281px) {
         padding-left: 100px;
         padding-right: 100px;
      }
      @media (max-width: 1280px) {
         padding-left: 50px;
         padding-right: 50px;
      }
      @media (max-width: 991px) {
         padding-left: 0;
         padding-right: 0;
      }
   }
   .custom-spacing-container {
      @media (max-width: 1600px) {
         padding-left: 4rem;
         padding-right: 4rem;
      }
      @media (max-width: 1368px) {
         padding-left: 3rem;
         padding-right: 3rem;
      }
      @media (max-width: 991px) {
         padding-left: 1rem;
         padding-right: 1rem;
      }
   }
   .container-zone {
      @media (max-width: 991px) {
         padding-left: 0;
         padding-right: 0;
      }
   }
   .select2-container .selection .select2-selection .select2-selection__rendered {
      &[title="."] {
         color: silver;
      }
   }
   .section-header {
      padding-top: 42px;
      padding-bottom: 75px;
      @media (max-width: 991px) {
         padding: 0;
      }
      .section-title {
         margin-top: 0;

         @media (max-width: 991px) {
            padding: 1rem;
            margin-bottom: 0;
         }
         @media (max-width: 767px) {
            padding: 10px 15px;
         }
      }
   }
   &-header {
      @include respond-above("md") {
         padding-right: 50px;
      }
      @include respond-below("md") {
         background-color: #fff;
         padding: 25px 25px 20px;
      }
      @include respond-below("sm") {
         padding: 15px;
      }

      .line-block {
         position: relative;
         display: block;
         width: 1px;
         height: 53px;
         background: #d8d8d8;
         margin: 0 50px;
         @media (max-width: 991px) {
            width: 100%;
            height: 1px;
            margin: 10px 0 20px;
         }
         @media (max-width: 767px) {
            margin: 10px 0;
         }
      }
      &__info {
         position: relative;
         .primary-select {
            position: absolute;
            width: 100%;
            right: 0;
            left: 0;
            bottom: -95px;
            opacity: 0;
            visibility: hidden;
            transition: 300ms ease-in-out;
            &:before {
               content: "";
               width: 0;
               height: 0;
               border-left: 27px solid transparent;
               border-right: 27px solid transparent;
               border-bottom: 20px solid #fff;
               position: absolute;
               left: 50%;
               top: 0;
               transform: translateX(-50%);
            }
            &.open {
               bottom: -75px;
               opacity: 1;
               visibility: visible;
               z-index: 1;
               @media (max-width: 991px) {
                  bottom: -90px;
               }
            }
            .select2-container {
               .selection {
                  .select2-selection {
                     border-color: transparent !important;
                     @media (max-width: 991px) {
                        box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
                        border-radius: 5px;
                     }
                  }
               }
               &.open {
                  .selection {
                     .select2-selection {
                        @media (max-width: 991px) {
                           border-bottom-left-radius: 0 !important;
                           border-bottom-right-radius: 0 !important;
                        }
                     }
                  }
               }
            }
         }
      }
      &__info-img {
         margin-right: 15px;
      }
      &__info-inner {
         .requested {
            strong,
            span {
               color: $color_yellow;
            }
         }
         .file-number {
            strong,
            span {
               color: #484848;
            }
         }
         .city {
            strong,
            span {
               color: #000;
            }
            .icon {
               cursor: pointer;
               margin-left: 5px;
            }
         }
         .che span, .che strong, .che_init{
            font-size: 12px;
            line-height: 1.3;
         }
         .requested,
         .file-number,
         .city {
            line-height: 1.2;
            strong {
               font-weight: 500;
            }
            span {
               font-weight: 300;
            }
            strong,
            span {
               font-size: 12px;
               line-height: 1.3;
            }
            strong + span {
               margin-left: 7px;
            }
         }
      }
      &__status {
         .status {
            font-size: 12px;
            font-weight: 500;
            @media (max-width: 991px) {
               order: 2;
            }
            @media (max-width: 767px) {
               font-size: 11px;
            }
         }
         .icon {
            font-size: 20px;
            margin-left: 5px;
            position: relative;
            top: -1px;
            @media (max-width: 991px) {
               order: 1;
            }
         }
      }
   }
   .list-step-form {
      position: relative;
      padding: 35px;
      margin-top: -40px;
      background-color: #3c2716;
      @media (min-width: 1192px) {
         margin-right: 45px;
      }
      &__prev,
      &__next {
         position: absolute;
         top: 51%;
         transform: translateY(-50%);
         @media (max-width: 991px) {
            &.show {
               display: block;
            }
         }
         .icon {
            color: #fff;
         }
      }
      &__prev {
         display: none;
         left: 15px;
      }
      &__next {
         display: none;
         right: 15px;
         @media (max-width: 991px) {
            display: block;
            &.hidden {
               display: none;
            }
         }
      }
      @media (max-width: 991px) {
         margin: 0;
         padding: 20px 15px;
      }
      @media (max-width: 767px) {
         padding: 15px;
      }
      &__inner {
         @media (min-width: 1281px) {
            padding: 0 70px;
         }
         @media (max-width: 1280px) {
            padding: 0 20px;
         }
         .item {
            &.active {
               a {
                  color: #f9b233;
               }
            }
            &.finished {
               a {
                  cursor: pointer;
                  color: #fff;
               }
            }
            a {
               font-weight: 500;
               font-size: 15px;
               text-transform: uppercase;
               color: #7d4e24;
               cursor: default;
               display: block;
               text-align: center;
               @media (max-width: 1191px) {
                  font-size: 13px;
               }
            }
            @media (max-width: 991px) {
               height: 0;
               opacity: 0;
               visibility: hidden;
               transition: 250ms ease-in-out;
               &.active {
                  opacity: 1;
                  visibility: visible;
                  height: auto;
                  &.finished {
                     height: 0;
                     opacity: 0;
                     visibility: hidden;
                  }
               }
            }
         }
      }
   }
   &-step-title {
      h3 {
         font-weight: 300;
         font-size: 30px;
         margin-bottom: 0;
         @media (max-width: 767px) {
            font-size: 20px;
         }
      }
      .number {
         margin-right: 20px;
         font-size: 23px;
         font-weight: 300;
         flex: 0 0 35px;
         width: 35px;
         height: 35px;
         color: #6ebce9;
         border: 1px solid;
         border-radius: 50%;
         text-align: center;
         @media (max-width: 414px) {
            flex: 0 0 40px;
            height: 40px;
            line-height: 1.65;
            margin-right: 15px;
         }

         @media (max-width: 320px) {
            flex: 0 0 35px;
            height: 35px;
            line-height: 1.5;
         }
      }
   }
   .info-person-form-wrap {
      @media (min-width: 1121px) {
         width: 790px;
      }
      @media (min-width: 1200px) {
         width: 820px;
      }
      @media (min-width: 1400px) {
         width: 920px;
      }
      @media (max-width: 1120px) {
         width: 670px;
      }
      @media (max-width: 991px) {
         width: 100%;
      }
   }

   .info-person-student {
      .info-person-form-wrap {
         width: auto;
      }
   }

   .step-section {
      margin-top: 50px;
      @media (max-width: 991px) {
         margin-top: 30px;
      }
      .form-group-radio-promis {
         margin: 8px 25px 0 29px;
         max-width: 215px;
         @media (max-width: 991px) {
            margin-right: 35px;
            max-width: 100%;
         }
      }

      &.demandeur-form-step-cause {
         .primary-form {
            padding: 80px 55px 0;
            @media (max-width: 991px) {
               padding: 60px 0 0;
            }
            @media (max-width: 767px) {
               padding: 40px 0 0;
            }
         }
         .step-submit {
            margin-bottom: 20px;
         }
      }

      &.demandeur-form-step-cause,
      &.demandeur-form-step-contact,
      &.demandeur-form-step-student,
      &.demandeur-form-step-family,
      &.demandeur-form-step-resource,
      &.demandeur-form-step-summary,
      &.demandeur-form-step-material,
      &.demandeur-form-step-construction,
      {
         margin: 0;
         overflow: hidden;
         opacity: 0;
         visibility: hidden;
         height: 0;
         transition: 300ms ease-in-out;
         &.show {
            opacity: 1;
            visibility: visible;
            height: auto;
            margin-top: 50px;
            margin-bottom: 1.125rem;

            @media (max-width: 767px) {
               margin-top: 30px;
            }
         }
      }

      .show-role {
         margin-top: 10px;
         padding: 11px 15px;
         font-size: 14px;
         font-weight: 300;
         color: #000;
         border-radius: 5px;
         background-color: #f7f7f7;
         border: 1px solid #eee;
         text-overflow: ellipsis;
         overflow: hidden;
      }
   }
   .step-submit {
      margin-top: 50px;
      @media (max-width: 991px) {
         margin-top: 40px;
      }
      @media (max-width: 767px) {
         margin-top: 20px;
      }
      .btn {
         width: 170px;
         margin-left: 10px;
         margin-right: 10px;
         @media (min-width: 992px) {
            margin-left: 15px;
            margin-right: 15px;
         }
         @media (max-width: 414px) {
            width: 50%;
            margin-left: 5px;
            margin-right: 5px;
         }
         @media (max-width: 320px) {
            margin-left: 0;
            margin-right: 5px;
            + .btn {
               margin-left: 5px;
               margin-right: 0;
            }
         }
      }
      .prev-form-action,
      .next-form-action,
      .return-action {
         .text {
            @media (min-width: 415px) {
               width: 110px;
            }
         }
      }
      .prev-form-action {
         .icon {
            margin-right: 5px;
         }
      }
      .next-form-action {
         .icon {
            margin-left: 5px;
         }
      }
      .return-action {
         .icon {
            margin-left: 5px;
         }
      }
   }
   .primary-form.custom-form {
      .radio-group {
         .bmd-form-group {
            .radio {
               label {
                  @media (max-width: 991px) {
                     padding-left: 35px;
                     margin-bottom: 0;
                  }
               }
               .bmd-radio {
                  + span {
                     @media (max-width: 991px) {
                        margin: 0;
                     }
                  }
               }
            }
         }
      }
      .form-group {
         &.form-group-radio-promis.bmd-form-group {
            &.none {
               display: none;
               + .error-message {
                  display: none;
                  margin-top: 0;
               }
            }
            + .error-message {
               .error {
                  margin-top: 20px;
                  padding-left: 30px;
               }
            }
            &.is-focused,
            &.is-filled {
               .bmd-label-floating {
                  padding-left: 0;
               }
            }
            .bmd-label-floating {
               padding-left: 0px;
            }
         }
         &.form-group-radio-autres.bmd-form-group {
            margin-top: 10px;
            border-color: $color_gray_light_03;
            position: relative;
            overflow: hidden;
            margin-right: 0;
            @media (max-width: 991px) {
               margin-top: 15px;
            }
            + .error-message {
               .error {
                  padding-left: 0;
               }
            }
         }
      }
      .radio-group-next-error {
         @media (max-width: 991px) {
            margin-bottom: 30px;
         }
         + .error-message {
            .error {
               margin-top: 10px;
               @media (max-width: 991px) {
                  margin-top: 0;
                  margin-bottom: 30px;
               }
            }
         }
      }
   }
}

.custom-spacing-container {
   padding-left: 15px;
   padding-right: 15px;
   @media (min-width: 768px) {
      padding-left: 1rem;
      padding-right: 1rem;
   }
   @media (min-width: 992px) {
      padding-left: 2rem;
      padding-right: 2rem;
   }
   @media (min-width: 1200px) {
      padding-left: 4rem;
      padding-right: 4rem;
   }
   @media (min-width: 1400px) {
      padding-left: 10rem;
      padding-right: 10rem;
   }
}

.custom-spacing-list-step-form {
   @media (max-width: 991px) {
      background-color: #3c2716;
   }
}

.demandeur-form-step-cause {
   .step-submit {
      @media (max-width: 414px) {
         justify-content: center;
         width: 100%;
      }
   }
   .autres-group {
      margin-top: 60px;
      @media (max-width: 991px) {
         margin-top: 10px;
      }
   }
   .custom-form {
      .radio-group {
         .bmd-form-group .radio label,
         .bmd-form-group label {
            margin-bottom: 0;
         }
         .bmd-form-group {
            .radio {
               span:not(.bmd-radio) {
                  margin: 0 8px;
               }
            }
         }
      }
   }
}

.demandeur-form-step-family {
   .step-family-dn-message {
      color: red;
      line-height: 1.3;
      font-size: 13px;
      margin-bottom: 0;
      position: relative;
      top: -7px;
   }
   &.step-section {
      .info-block {
         margin-bottom: 0;
         @media (max-width: 999px) {
            display: flex;
            align-items: center;
            justify-content: space-between;
         }
      }
   }
   .step-submit {
      margin-bottom: 50px;
   }
   .step-add-more {
      margin-top: 30px;
      min-width: 230px;
   }
   .primary-form {
      &.custom-form {
         .radio-group {
            &.pmr-radio {
               .label-title {
                  padding-top: 10px;
                  margin-bottom: 5px;
               }
            }
         }
         .form-group {
            @media (max-width: 414px) {
               margin-bottom: 5px;
            }
            + .error-message.has {
               margin-top: 15px;
               @media (max-width: 414px) {
                  margin-top: 5px;
               }
            }
         }
         .error-message {
            &:not(.has) {
               margin-top: 0;
            }
            &.has {
               .error {
                  margin-top: -5px;
               }
            }
         }
      }
   }
   .primary-form .form-group-upload {
      margin-top: 30px;
   }
   .form-group-upload__inner {
      margin-bottom: -20px;
      .item {
         margin-bottom: 20px;
      }
   }
   .note-upload {
      color: #c0c0c0;
      font-size: 14px;
      @media (min-width: 1200px) {
         margin-left: 10px;
      }
   }
   .info-person {
      position: relative;
      padding-bottom: 50px;
      margin-top: 50px;
      + .info-person {
         margin-top: 0;
      }
      &:last-child {
         border-bottom: 1px solid #6ebce9;
      }
      &.person-1 {
         .upload-releve {
            display: block;
         }
      }

      .nom_de_jeune_fille-area,
      .upload-certificat,
      .upload-releve-information,
      .upload-releve,
      .upload-school-certificate {
         display: none;
      }
      &-control {
         @media (min-width: 1000px) {
            position: absolute;
            top: 50px;
            right: 0;
         }
         @media (min-width: 1200px) {
            top: 45px;
         }
         .icon {
            cursor: pointer;
            font-size: 45px;
            @media (min-width: 415px) {
               font-size: 50px;
            }
         }
         .remove-person {
            color: $color_yellow;
         }

         .collapse-block + .remove-person {
            margin-left: 15px;
            @media (max-width: 1199px) {
               margin-left: 10px;
            }
            @media (max-width: 414px) {
               margin-left: 5px;
            }
         }
      }
      .collapse-block {
         transition: 300ms ease-in-out;
         &.open {
            transform: rotate(-180deg);
         }
      }
      .collapse-block-translate {
         opacity: 0;
         height: 0;
         &.open {
            opacity: 1;
            height: auto;
         }
      }
   }
}

.demandeur-form-step-resource {
   .info-person-clone-wrap {
      margin-top: 15px;
      @media (max-width: 991px) {
         margin-bottom: 20px;
      }
   }
   .step-submit {
      margin-top: 70px;
      margin-bottom: 50px;

      @media (max-width: 991px) {
         margin-top: 50px;
         margin-bottom: 40px;
      }
   }
   &.step-section {
      .info-block {
         margin-bottom: 5px;
         @media (max-width: 991px) {
            padding-bottom: 0;
         }
      }
   }
   .custom-col {
      position: relative;
      .line-total {
         margin-top: 57px;
         @media (max-width: 991px) {
            margin-top: 10px;
         }
      }
   }
   .note {
      font-size: 15px;
      margin-top: 40px;
      @media (max-width: 991px) {
         margin-bottom: 10px;
      }
   }
   .info-person {
      &__money {
         position: relative;
         margin: 0 -15px;
         padding-bottom: 40px;
         .custom-col {
            flex: 0 0 20%;
            max-width: 20%;
            padding: 0 15px;
            @media (max-width: 991px) {
               max-width: 100%;
               flex: 0 0 auto;
               + .custom-col {
                  margin-top: 15px;
               }
            }
         }
      }
      &__money-nom,
      &__money-prenom {
         font-size: 15px;
         font-weight: 300;
      }
      &__money-nom {
         @media (max-width: 991px) {
            margin-right: 5px;
         }
      }
      &__total {
         padding-top: 40px;
         border-top: 1px solid #6ebce9;
         @media (max-width: 991px) {
            padding-top: 20px;
         }
         .label-title {
            font-weight: 600 !important;
         }
         .prm-input{
            display: none;
            &.open {
                  display: block;
            }
            padding-left: 2.15rem;
            flex: 0 0 25%;
            max-width: 25%;
            @media (max-width: 991px) {
               width: 100%;
               max-width: 50%;
               flex: 0 0 auto;
            }
            @media (max-width: 540px) {
               max-width: 80%;
            }
         }

         .sans-emprunt-input{
            display: none;
            &.open {
               display: block;
            }
            padding-left: 2.15rem;
            flex: 0 0 25%;
            @media (max-width: 991px) {
               width: 100%;
               max-width: 50%;
               flex: 0 0 auto;
            }
            @media (max-width: 540px) {
               max-width: 80%;
            }
            .bmd-form-group {
               flex: 0 0 25%;
               max-width: 25%;
               padding-top: 0 !important;
               margin-right: 1.5rem !important;
            }
         }

         .location-input{
            display: none;
            &.open {
               display: block;
            }
            padding-left: 2.15rem;
            flex: 0 0 25%;
            max-width: 25%;
            @media (max-width: 991px) {
               width: 100%;
               max-width: 50%;
               flex: 0 0 auto;
            }
            @media (max-width: 540px) {
               max-width: 80%;
            }
         }

         .radio-resources {
            .bmd-form-group {
               flex: 0 0 25%;
               max-width: 25%;
            }
         }
         .note {
            margin-top: 0;
            font-weight: 500;
         }
         .total-wrap {
            margin-top: 10px;
         }
         .total-both-3m {
            background-color: transparent;
            border: 1px solid #c0c0c0;
            color: #c0c0c0;
         }
      }
   }
   .primary-form.custom-form {
      .form-group {
         margin-bottom: 0;
         margin-top: 0;
      }
      .error-message {
         margin-top: 5px;
      }
   }
   .total-both-3m {
      font-size: 14px;
      font-weight: 300;
      border-radius: 5px;
      height: 45px;
      padding: 10px 15px;
      line-height: 1.9;
      background-color: #f7f7f7;
      color: #c0c0c0;
      cursor: default;
   }
}


.demandeur-form-step-material {
   .note {
      font-size: 15px;
      margin-top: 40px;
      @media (max-width: 991px) {
         margin-bottom: 10px;
      }
   }
}

.demandeur-form-step-summary {
   .get-motif-demande {
      padding: 20px 50px;
      .label {
         width: 300px;
      }
   }
   .construction-summary {
      padding: 20px 50px;
      .label {
         width: 300px;
      }
   }
   .coordinate-summary{
      .label {
         width: 300px;
      }
   }
   .demandeur-step-title {
      color: #6ebce9;
      .number {
         margin-right: 15px;
      }
   }
   .block-btn-mobile {
      padding: 10px 50px 15px;
   }
   .step-block-final {
      padding: 30px 0 5px;
      @media (max-width: 991px) {
         border-bottom: 1px solid #6ebce9;
      }
      + .step-block-final {
         @media (min-width: 992px) {
            border-top: 1px solid #6ebce9;
         }
      }
      .btn.btn-outline-yellow {
         padding: 0.5rem 1.5rem;
      }
   }
   .container-step-block {
      padding: 20px 50px;
      line-height: 1.7;
      @media (max-width: 991px) {
         padding: 20px 55px;
         .item {
            + .item {
               margin-top: 15px;
            }
         }
      }
   }
   .step-submit {
      margin-top: 80px;
      margin-bottom: 50px;
      @media (max-width: 992px) {
         margin-top: 40px;
         margin-bottom: 40px;
      }
   }
   .step-contact-block-final,
   .step-construction-block-final {
      .text-default {
         @media (min-width: 992px) {
            padding-left: 5px;
         }
      }
   }
   .step-family-block-final,
   .step-resource-block-final,
   .step-material-block-final {
      & .family-content {
         padding: 20px 50px;
      }
      .resource-content, .material-content {
         padding: 20px 50px;
      }
      .label {
         width: 150px;
         flex-shrink: 0;
         @media (max-width: 1191px) {
            width: 135px;
         }
      }
      .text-default {
         @media (min-width: 1192px) {
            padding-left: 15px;
         }
         @media (min-width: 992px) and (max-width: 1191px) {
            padding-left: 10px;
         }
      }
      .person-border {
         border: 1px solid #fbfbfb;
         padding: 0px;
         @media (max-width: 991px) {
            padding: 0px;
         }
      }
      .person-name-space {
         width: 30%;
      }
      .revenue-space {
         width: 20%;
      }
   }

   .step-material-block-final {
      .material-content {
         padding: 20px 0;
      }
   }

   .resources-table{
      display: flex;
      flex-direction: column;

      &>*:not(:last-child){
         margin-bottom: 1rem;
      }

      .resources{
         display: flex;
         flex-wrap: wrap;
         font-size: 15px;

         .resource{
            display: grid;
            grid-template-columns: max-content 1fr;
            column-gap: 1rem;
            margin-bottom: 1rem;
         }
      }

      .total{
         align-self: flex-start;
         font-size: 15px;

         .box_total {
            font-size: 15px;
            font-weight: 300;
            border-radius: 5px;
            height: 45px;
            padding: 10px 15px;
            line-height: 1.9;
            background-color: #f7f7f7;
            color: #3c2716;
            cursor: default;
         }
      }
   }
   .label {
      font-size: 15px;
   }
   .demande-filled-hidden {
      display: none !important;
   }
   .prm-input{
      display: none;
      &.open {
         display: block;
      }
      padding-left: 2.15rem;
      flex: 0 0 25%;
      max-width: 25%;
      @media (max-width: 991px) {
         width: 100%;
         max-width: 50%;
         flex: 0 0 auto;
      }
      @media (max-width: 540px) {
         max-width: 80%;
      }
   }
   .sans-emprunt-input{
      display: none;
      &.open {
         display: block;
      }
      padding-left: 2.15rem;
      flex: 0 0 25%;
      @media (max-width: 991px) {
         width: 100%;
         max-width: 50%;
         flex: 0 0 auto;
      }
      @media (max-width: 540px) {
         max-width: 80%;
      }
      .bmd-form-group {
         flex: 0 0 25%;
         max-width: 25%;
         padding-top: 0 !important;
         margin-right: 1.5rem !important;
      }
   }
   .location-input{
      display: none;
      &.open {
         display: block;
      }
      padding-left: 2.15rem;
      flex: 0 0 25%;
      max-width: 25%;
      @media (max-width: 991px) {
         width: 100%;
         max-width: 50%;
         flex: 0 0 auto;
      }
      @media (max-width: 540px) {
         max-width: 80%;
      }
   }
}

#demande_cause {
   display: flex;
   flex-wrap: wrap;
   > .custom-checkbox {
      width: 50%;
      margin-bottom: 15px;

      &:nth-child(even) {
         padding-left: 50px;
      }

      @media (max-width: 991px) {
         width: 100%;

         &:nth-child(even) {
            padding-left: 0;
         }
      }
   }
}

.autres-group {
   .custom-checkbox {
      width: 100% !important;
      flex: 0 0 100% !important;
   }
}

.custom-checkbox {
   input[type="checkbox"] {
      visibility: hidden;
      appearance: none;
      opacity: 0;
      position: absolute;
      &:checked  {
         + label {
            &:before {
               border: 2px solid #f9b233;
            }
            &:after {
               opacity: 1;
            }
         }
      }
   }
   label.cause_label, label.material, label.specificite, label.popup_summary {
      display: flex;
      position: relative;
      padding-left: 0;
      color: inherit;
      font-size: 15px;
      font-weight: 300;
      &:hover {
         color: inherit;
      }
      &:before {
         content: '';
         min-width: 20px;
         height: 20px;
         margin-right: 10px;
         display: block;
         border: 2px solid rgba(0,0,0,.54);
         border-radius: 3px;
      }
      &:after {
         content: '';
         position: absolute;
         width: 11px;
         height: 7px;
         top: 5px;
         left: 5px;
         border: 3px solid #f9b233;
         border-top: none;
         border-right: none;
         background: transparent;
         opacity: 0;
         transition: 200ms ease-in-out;
         transform: rotate(-45deg);
      }
   }
}

.black-line {
   border-top: 2px solid $color_brown_dark;
   span {
      font-weight: 600;
      font-size: 20px;
   }
}

#demandeur-form-step-construction {
   .first_block_step_construction {
      .custom-form{
         .form-group {
            display: block;
         }
      }
   }
   .permit-info-block {
      margin-top: 10px;
      .form-check {
         padding-left: 0;
      }
      &.row-2,
      &.row-3,
      &.row-5{
         padding-left: 16px;
      }
   }
   .primary-form {
      .form-group-upload {
         margin-top: 0;
      }
   }
   .cadastrale_search {
      position: absolute;
      right: 0;
      top: 10px;
      font-size: 18px;
      &:hover {
         background: none;
      }
   }
}

.materiaux_block {
   border-top: 2px solid $color_brown_dark;

   .title_block {
      font-size: 1.5rem;
      color: $color_brown;
      font-weight: 400;
   }
   .detail_amount_block {
      opacity: 0;
      transition: opacity 0.3s;
      &.show {
         opacity: 1;
      }
   }
   .amount_total {
      font-size: 1.5rem;
      font-weight: 300;
   }
   .color_blue_light {
      color: $color_blue_light;

   }
   .detail_list_link {
      opacity: 0.5;
      &:hover {
         opacity: 1;
      }
   }
}

.card {
   box-shadow: none;
   .card-header {
      border-top: 1px solid $color_blue_light;
      border-bottom: 0;
      &:first-child {
         border-radius: 0;
      }
      .btn-accordion {
         display: block;
         width: 100%;
         text-align: left;
         padding-left: 1rem;
         padding-right: 1rem;
         text-transform: none;
         font-size: 1.2rem;
         font-weight: 300;
         margin: 0;
         position: relative;
         &:after {
            content: "\e900";
            font-family: oph !important;
            font-style: normal;
            font-weight: 400;
            float: right;
            transform: rotate(0deg);
            transition: all 0.4s;
            color: $color_brown_dark;
            font-size: 2rem;
         }
         &:hover, &:focus, &:active {
            background: none;
            border: 0;
         }
         &.collapsed {
            &:after {
               transform: rotate(180deg);
            }
         }
      }
   }

   .card-body {
      input[type='number'].form-control2 {
         -moz-appearance: number-input;
         border: 1px solid $color-gray;
         border-radius: 10px;
         padding-left: 10px;
         padding-right: 10px;
         display: inline-block;
         max-width: 80px;
      }
      .table {
         .code {
            white-space: nowrap;
         }
         .price {
            white-space: nowrap;
         }
      }
   }
}

.first_block_step_material {
   transition: all 0.3s;
   visibility: visible;
   opacity: 1;

   &.hide {
      visibility: hidden;
      opacity: 0;
      height: 0;
   }
}

.materiaux_liste_detail {
   visibility: hidden;
   opacity: 0;
   height: 0;
   transition: all 0.3s;

   &.show {
      height: auto;
      visibility: visible;
      opacity: 1;
   }

   .block_title {
      .btn-back {
         font-size: 30px;
         margin-right: 1rem;
         vertical-align: middle;
      }
      span {
         font-size: 25px;
         font-weight: 500;
         color: $color_brown_dark;
      }
   }

   input[type='number'].form-control2 {
      -moz-appearance: number-input;
      border: 1px solid $color-gray;
      border-radius: 10px;
      padding-left: 10px;
      padding-right: 10px;
      display: inline-block;
      max-width: 80px;
   }
   .table {
      .code {
         white-space: nowrap;
      }
      .price {
         white-space: nowrap;
      }
   }
   .qte {
      width: 110px;
   }
   .actions {
      width: 50px;
      text-align: right;
      .remove_unit {
         background: none;
         border: 0;
         box-shadow: none;
         color: $color_yellow;
         font-size: 30px;
      }
   }
}

.modal {
   &--gray {
      border-radius: 5px;
      color: #000;
      .modal-content {
         background-color: #ffffff;
         background-image: linear-gradient(180deg, #ffffff 0%, #ffffff 74%);
         .modal-body p {
            font-size: 14px;
         }
      }
      .btn-gray {
         background: #fff;
         min-width: 200px;
         text-transform: inherit;
      }
      .select2-container {
         min-width: 200px;
         .select2-selection {
            background-color: #ffffff;
         }
      }
   }
   .avertissement-content {
      max-height: 350px;
      border-radius: 5px;
      border: 1px solid #000;
      overflow-y: scroll;
      padding: 0.5rem;
      font-size: 14px;
   }
   .popup_summary {
      font-size: 14px;
   }
   #form_demande_survey {
      .custom-form {
         margin-left: inherit;
         max-width: 100%;
         &.commentaire {
            clear: both;
         }
      }
   }
}

.reclamation-alert-message, .profil-alert-message, .composition-alert-message, .assurance-alert-message, .rib-alert-message {
   background-color: #6ebce987;
   padding: 1.5rem 0;
   text-align:center;
   color: #3c2716;
   font-size: 14px;
   margin-bottom: -2rem;
}

.demande-alert-message {
   background-color: #6ebce987;
   padding: 1.5rem 0;
   text-align:center;
   color: #3c2716;
   font-size: 14px;
}

.loader_div{
   opacity:0.7;
   display:none;
   position: fixed;
   left: 0;
   top: 0;
   width: 100%;
   height: 100%;
   z-index: 9999;
   background: url('../../images/loading_oph.gif') center center no-repeat;
}

#confirm_cadastral_dialog {
   .custom-form {
      @media screen and (min-width: 992px) {
         max-width: 100%;
      }
      .label {
         word-break: break-word;
         font-size: 1.2rem;
      }
      hr {
         border-top: 1px solid #6ebce9;
      }
      .radio-label {
         display: block;
      }
      .radio {
         label {
            position: relative;
         }
      }
      input[type="radio"]:checked ~ .radio-label {
         font-weight: 400;
         color: #000
      }
   }
   .radio-group {
      .row {
         word-break: break-word;
      }
   }

   #form_confirm_cadastral {
      max-height: 300px;
      overflow-y: auto;
      overflow-x: hidden;
   }
}
#demandeur-form-step-summary .select2-container--default.select2-container--disabled .select2-selection--single,
#demandeur-form-step-summary .custom-file-control:disabled,
#demandeur-form-step-summary .custom-file-control[readonly],
#demandeur-form-step-summary .form-control:disabled, .form-control[readonly] {
   background-color: transparent;
}
#rating {
   float: left;
   height: 46px;
   padding: 0 10px;
}
#rating:not(:checked) > input {
   position:absolute;
   top:-9999px;
}
#rating:not(:checked) > label {
   float:right;
   width:1em;
   overflow:hidden;
   white-space:nowrap;
   cursor:pointer;
   font-size:30px;
   color:#ccc;
}
#rating:not(:checked) > label:before {
   content: "\f005";
}
#rating > input:checked ~ label {
   color: #ffc700;
}
#rating:not(:checked) > label:hover,
#rating:not(:checked) > label:hover ~ label {
   color: #ffdc6d;
}
#rating > input:checked + label:hover,
#rating > input:checked + label:hover ~ label,
#rating > input:checked ~ label:hover,
#rating > input:checked ~ label:hover ~ label,
#rating > label:hover ~ input:checked ~ label {
   color: #ffdc6d;
}

.demandeur-header__info .primary-select3 {
   position: absolute;
   width: 100%;
   right: 0;
   left: 0;
   bottom: -95px;
   opacity: 0;
   visibility: hidden;
   transition: 300ms ease-in-out; }
.demandeur-header__info .primary-select3:before {
   content: "";
   width: 0;
   height: 0;
   border-left: 27px solid transparent;
   border-right: 27px solid transparent;
   border-bottom: 20px solid #fff;
   position: absolute;
   left: 50%;
   top: 0;
   transform: translateX(-50%); }
.demandeur-header__info .primary-select3.open {
   bottom: -75px;
   opacity: 1;
   visibility: visible;
   z-index: 1; }
@media (max-width: 991px) {
   .demandeur-header__info .primary-select3.open {
      bottom: -90px; } }
.demandeur-header__info .primary-select3 .select2-container .selection .select2-selection {
   border-color: transparent !important; }
@media (max-width: 991px) {
   .demandeur-header__info .primary-select3 .select2-container .selection .select2-selection {
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
      border-radius: 5px; } }


.demandeur-form-wrapper .primary-select3 {
   position: absolute;
   width: 100%;
   right: 0;
   left: 0;
   bottom: -95px;
   opacity: 0;
   visibility: hidden;
   transition: 300ms ease-in-out; }
.demandeur-form-wrapper .primary-select3:before {
   content: "";
   width: 0;
   height: 0;
   border-left: 27px solid transparent;
   border-right: 27px solid transparent;
   border-bottom: 20px solid #fff;
   position: absolute;
   left: 50%;
   top: 0;
   transform: translateX(-50%); }
.demandeur-form-wrapper .primary-select3.open {
   bottom: -75px;
   opacity: 1;
   visibility: visible;
   z-index: 1; }
@media (max-width: 991px) {
   .demandeur-form-wrapper .primary-select3.open {
      bottom: -90px; } }
.demandeur-form-wrapper .primary-select3 .select2-container .selection .select2-selection {
   border-color: transparent !important; }
@media (max-width: 991px) {
   .demandeur-form-wrapper .primary-select3 .select2-container .selection .select2-selection {
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
      border-radius: 5px; } }

.picto-top-right {
   top: -10px;
   right: 0;
}